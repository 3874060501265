<script setup lang="ts">
import { Header } from '@ds-gala-front-app/shared'
import { ElContainer, ElMain } from 'element-plus'
import ExpiredDialog from '@/components/LoginDialog/ExpiredDialog.vue'

const route = useRoute()
const { show: showLoginDialog, redirectAfterLogin } = useLoginDialog()
const { userInfo, isLogin } = useUser()

const isShowLoginTip = ref(false)

function onSearch(keyword: string, path: string) {
  if (keyword) {
    window.collectEvent('click_search', {
      page_title: document.title,
      search_source: route.meta?.title ?? '',
      keyword,
    })

    if (!isLogin.value) {
      showLoginDialog()
      return
    }

    // if (!isUnexpired.value) {
    //   // identityShowLoginDialog(true, true)
    //   isShowLoginTip.value = true
    //   showExpiredDialog()
    //   return
    // }
  }

  navigateTo({
    path,
    query: { keyWord: keyword },
  })
}

async function handleLogout() {
  await logout()
  location.reload()
}

const newVersionLink = ref('https://seller.glodastory.com/')
onMounted(() => {
  const { ENV } = useRuntimeConfig().public
  newVersionLink.value = ENV === 'prod' ? 'https://seller.glodastory.com/' : '/next'
})
</script>

<template>
  <ElContainer class="relative w-100vw" direction="vertical">
    <img fixed w-full src="@/assets/img/layout-bg.png">
    <div class="fixed top-0 z-1 h-full w-full overflow-hidden">
      <div class="app-container__bg1" />
      <div class="app-container__bg2" />
      <div class="app-container__bg3" />
      <div class="app-container__bg4" />
    </div>

    <LoginDialog :is-show-login-tip="isShowLoginTip" />
    <ExpiredDialog />

    <Header
      class="fixed top-0 z-999"
      :user-info="userInfo"
      :newVersionLink="newVersionLink"
      @login="redirectAfterLogin('/analysis/products'), showLoginDialog()"
      @logout="handleLogout"
      @search="onSearch"
    />

    <ElMain style="--el-main-padding: 0" class="relative left-0 top-0 z-2 min-h-100vh w-full overflow-auto">
      <slot />
    </ElMain>
  </ElContainer>
</template>

<style scoped lang="scss">
.app-container__bg1 {
  width: 975px;
  height: 200%;
  background: url('//cdn-static.glodastory.com/ds-gala-front-app-web/assets/ionic_mint_gradient-3peZE-yf.png')
    no-repeat left top;
  background-size: contain;
  top: -487.5px;
  left: -487.5px;
  position: absolute;
  z-index: 1;
  transition: opacity 1;
  translate: none;
  scale: none;
  rotate: none;
  animation: gradient-animation1 17s linear 0s infinite;
  -webkit-user-select: none;
  user-select: none;
  will-change: transform;
}
@keyframes gradient-animation1 {
  0% {
    transform: translate3d(307.5px, calc(60px * 0.75), 0px) rotate(0);
  }

  20% {
    transform: translate3d(307.5px, 0, 0) rotate(0);
  }

  40% {
    transform: translate3d(calc(390px * 0.75), calc(30px * 0.75), 0) rotate(0);
  }
  60% {
    transform: translate3d(307.5px, calc(10px * 0.75), 0px) rotate(0);
  }
  80% {
    transform: translate3d(calc(500px * 0.75), calc(10px * 0.75), 0) rotate(0);
  }

  to {
    transform: translate3d(307.5px, calc(60px * 0.75), 0) rotate(0);
  }
}

.app-container__bg2 {
  width: 1087.5px;
  height: 100%;
  background: url('//cdn-static.glodastory.com/ds-gala-front-app-web/assets/tag_blue_gradient-BNXY4hBh.png')
    no-repeat left top;
  background-size: contain;
  top: -543.75px;
  left: -543.75px;
  position: absolute;
  z-index: 2;
  filter: contrast(1.2);
  transition: opacity 1;
  translate: none;
  scale: none;
  rotate: none;
  animation: gradient-animation2 17s linear 0s infinite;
  -webkit-user-select: none;
  user-select: none;
  will-change: transform;
}
@keyframes gradient-animation2 {
  0% {
    transform: translate3d(calc(100px * 0.75), calc(680px * 0.75), 0px)
      rotate(0) scale(1);
  }

  20% {
    transform: translate3d(calc(882px * 0.75), calc(480px * 0.75), 0)
      rotate(75deg) scale(1);
  }

  40% {
    transform: translate3d(calc(980px * 0.75), calc(90px * 0.75), 0) rotate(0)
      scale(1.31);
  }
  60% {
    transform: translate3d(calc(500px * 0.75), calc(500px * 0.75), 0px)
      rotate(-10deg) scale(1);
  }
  80% {
    transform: translate3d(calc(150px * 0.75), calc(450px * 0.75), 0)
      rotate(-160deg) scale(1);
  }

  to {
    transform: translate3d(calc(100px * 0.75), calc(680px * 0.75), 0px)
      rotate(0) scale(1);
  }
}

.app-container__bg3 {
  width: 1350px;
  height: 200%;
  background: url('//cdn-static.glodastory.com/ds-gala-front-app-web/assets/ozone_blue_gradient-Cx2Y1OKe.png')
    no-repeat left top;
  background-size: contain;
  top: -675px;
  left: -675px;
  position: absolute;
  z-index: 3;
  transition: opacity 1;
  translate: none;
  scale: none;
  rotate: none;
  animation: gradient-animation3 17s linear 0s infinite;
  -webkit-user-select: none;
  user-select: none;
  will-change: transform;
}
@keyframes gradient-animation3 {
  0% {
    transform: translate3d(calc(-100px * 0.75), calc(400px * 0.75), 0px)
      rotate(0) scale(1);
  }

  20% {
    transform: translate3d(calc(620px * 0.75), calc(200px * 0.75), 0)
      rotate(30deg) scale(1.19);
  }

  40% {
    transform: translate3d(calc(600px * 0.75), calc(320px * 0.75), 0) rotate(0)
      scale(1.19);
  }
  60% {
    transform: translate3d(calc(280px * 0.75), calc(180px * 0.75), 0px)
      rotate(20deg) scale(0.86);
  }
  80% {
    transform: translate3d(calc(-85px * 0.75), calc(400px * 0.75), 0) rotate(0)
      scale(1);
  }

  to {
    transform: translate3d(calc(-100px * 0.75), calc(400px * 0.75), 0px)
      rotate(0) scale(1);
  }
}

.app-container__bg4 {
  width: 1050px;
  height: 200%;
  background: url(//cdn-static.glodastory.com/ds-gala-front-app-web/assets/phito_green_gradient-fa7p3D-H.png)
    no-repeat left top;
  background-size: contain;
  top: -525px;
  left: -525px;
  translate: none;
  scale: none;
  rotate: none;
  animation: gradient-animation4 17s linear 0s infinite;
  -webkit-user-select: none;
  user-select: none;
  will-change: transform;
  position: absolute;
  z-index: 4;
}
@keyframes gradient-animation4 {
  0% {
    transform: translate3d(187.5px, -210px, 0px) rotate(0) scale(1);
  }

  20% {
    transform: translate3d(750px, -37.5px, 0px) rotate(-10deg) scale(1.6);
  }

  40% {
    transform: translate3d(172.5px, 70px, 0px) rotate(-100deg) scale(1.65);
  }
  60% {
    transform: translate3d(22.5px, 7.5px, 0px) rotate(-10deg) scale(1.3);
  }
  80% {
    transform: translate3d(112.5px, -150px, 0px) rotate(0) scale(1);
  }

  to {
    transform: translate3d(187.5px, -210px, 0px) rotate(0) scale(1);
  }
}
</style>
