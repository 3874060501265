<script setup lang="ts">
import LoginForm from './LoginForm.vue'
import LoginTypeBtn from './LoginTypeBtn.vue'
import WxScan from './WxScan.vue'

const props = defineProps<{
  isShowLoginTip?: boolean
}>()

const router = useRouter()
const route = useRoute()

const { visible, hide } = useLoginDialog()
const { getPromotionCode, promotionCodeData } = useRegistrationPromotionCode()
const isFromKgj = computed(() => {
  return getPromotionCode() && promotionCodeData.value?.from === 'kgj'
})

const isScan = ref(true)
interface Option {
  label: string
  value: string
}
const tabOptions: Option[] = [{
  label: '密码登录',
  value: 'password',
}, {
  label: '短信登录/注册',
  value: 'message',
}]
const filterTabOptions = computed(() => {
  return !isScan.value
    ? tabOptions
    : [{
        label: '微信扫码登录',
        value: 'scan',
      }]
})
type TabActive = 'message' | 'password' | 'scan'
const tabActive = ref<TabActive>('password')

const showLoginTip = ref(props.isShowLoginTip || false)
watch(() => props.isShowLoginTip, () => {
  showLoginTip.value = props.isShowLoginTip
})

function handleTabClick(tab: Option) {
  tabActive.value = tab.value as TabActive
}
function onClose() {
  hide()
}

function onVisits() {
  const goUrl = '/analysis/products'
  const { path } = route
  if (path !== goUrl) {
    router.push({ path: goUrl })
    // useLoginStore.isCloseLoginDialogReload = false
  }
  else {
    // useLoginStore.isCloseLoginDialogReload = true
  }
  showLoginTip.value = false
  onClose()
}

function onOpened() {
  window.collectEvent('page_popup', {
    popup_name: '登录注册弹窗',
    page_title: document.title,
  })
}

const isRegister = ref(false)
function turnTo(val: boolean) {
  isRegister.value = !val
}
watch(() => isScan.value, (val) => {
  tabActive.value = !val ? 'password' : 'scan'
})
</script>

<template>
  <el-dialog
    v-model="visible"
    :show-close="false"
    width="940"
    :z-index="4000"
    :close-on-click-modal="false"
    class="gala-dialog login-dialog"
    @opened="onOpened"
  >
    <div class="relative h-600px" :class="{ '!h-650px': showLoginTip }">
      <div class="login-dialog__close" @click="onClose">
        <i class="ds-iconf-close-line" />
      </div>
      <div class="h-full w-full flex">
        <div class="login-dialog__left p-20px">
          <img src="@/assets/img/gloda-logo.svg" width="130px">
        </div>
        <div class="relative w-0 flex flex-1 flex-col pb-40px">
          <LoginTypeBtn v-model:value="isScan" v-model:is-register="isRegister" />
          <!-- <div class="flex justify-end relative">
            <div class="rounded-tr-16 w-78px h-78px hover:text-[var(--color-2)] cursor-pointer">
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 78" class="design-iconfont">
                <g clip-path="url(#tu68i7ntj__clip0_82_10317474)">
                  <path d="M0 0H78V78L0 0Z" fill="#E9ECF1" />
                  <g clip-path="url(#tu68i7ntj__clip1_82_10317474)">
                    <mask id="tu68i7ntj__w4de076cua" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="-1" width="78" height="79">
                      <path d="M0 -0.000488281L78 -0.000488281L78 77.9995L0 -0.000488281Z" fill="#EEE" />
                    </mask>
                    <g mask="url(#tu68i7ntj__w4de076cua)">
                      <path d="M62.853 14.9995H40.3104V19.6438H62.853V14.9995ZM34.5776 14.9995H21.5266V19.6438H34.5776V14.9995ZM16.7423 14.9995H12V19.7383H16.7423V14.9995ZM62.853 32.9787H40.3104V37.6265H62.853V32.9787ZM63 14.9995H58.3522V37.5251H63V14.9995ZM44.9582 14.9995H40.3104V37.5251H44.9582V14.9995ZM55.1428 23.4943H48.8151V29.8172H55.1428V23.4943ZM34.5776 19.6438H30.0523V24.4105H34.5776V19.6438ZM21.5301 19.6438H16.5673V28.4148H21.5301V19.6438ZM16.7423 24.4105H12V32.7094H16.7423V24.4105ZM29.8808 27.9672H21.5266V33.9405H29.8808V27.9672ZM25.205 32.7059H16.7073V37.5216H25.205V32.7059ZM34.5496 32.8563H29.8808V37.5216H34.5496V32.8563ZM63 42.1799H58.3522V52.7031H63V42.1799ZM58.3417 51.7133H53.3124V56.7424H58.3417V51.7133ZM63 56.543H58.3417V65.9995H63V56.543ZM53.5959 42.1799H39.2849V47.9573H53.5959V42.1799ZM50.1135 47.4432H44.1427V52.9339H50.1135V47.4432ZM45.2557 51.7133H39.2849V61.2678H45.2557V51.7133ZM49.8895 56.543H44.1427V65.9995H49.8895V56.543ZM54.6738 60.0123H49.095V65.9995H54.6738V60.0123ZM31.0917 42.1799H20.4137V51.7133H31.0917V42.1799ZM17.8588 42.1799H12V48.0343H17.8588V42.1799ZM35.6906 46.9466H29.8318V57.5642H35.6906V46.9466ZM26.3074 50.3774H20.4137V56.4906H26.3074V50.3774ZM35.6906 60.0123H29.8318V65.9995H35.6906V60.0123ZM21.5966 61.2678H12V65.9995H21.5966V61.2678ZM16.7983 56.4906H12V62.268H16.7983V56.4906Z" fill="currentColor" />
                    </g>
                  </g>
                </g>
                <defs>
                  <clipPath id="tu68i7ntj__clip0_82_10317474">
                    <path d="M0 0H62C70.8366 0 78 7.16344 78 16V78H0V0Z" fill="#fff" />
                  </clipPath>
                  <clipPath id="tu68i7ntj__clip1_82_10317474">
                    <path fill="#fff" transform="matrix(1 0 0 -1 4 73.9995)" d="M0 0H70V70H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="login-dialog__tooltip">
              扫码登录在这里
            </div>
          </div> -->
          <div class="h-full flex flex-col items-center justify-center">
            <!-- TODO: isShowLoginTip待确认作用 -->
            <div v-if="showLoginTip" class="mb-32px w-full b-0 b-b-1 b-[var(--color-grey-3)] border-solid pb-32px">
              <div class="mx-[auto] h-44px w-340px flex items-center justify-center rounded-30px bg-[var(--color-2-10)] text-14px color-[var(--color-1)] font-500">
                您当前是游客状态，登录立享
                <span class="color-[var(--color-2)]">{{ isFromKgj ? 14 : 3 }}天免费试用！</span>
              </div>
            </div>

            <div class="mx-[auto] w-340px flex flex-col items-center justify-center">
              <div v-if="!isRegister" class="relative mx-0 flex gap-32px pb-8px text-[26px]">
                <div
                  v-for="item in filterTabOptions"
                  :key="item.value"
                  :class="{ 'font-600': tabActive === item.value || isScan }"
                  class="cursor-pointer text-[#1D2129] transition-all duration-300"
                  @click="handleTabClick(item)"
                >
                  {{ item.label }}
                </div>
                <div v-if="!isScan" class="absolute bottom-0 h-4 w-104px rounded-8 bg-[var(--color-1)] transition-all duration-300" :class="`${tabActive === 'password' ? 'left-0' : 'left-166px'}`" />
              </div>
              <template v-if="!isScan">
                <LoginForm class="pt-40px" :model="tabActive" />
                <el-button
                  link
                  class="gala-button mb-20px font-400"
                  data-popup_name="登录注册弹窗"
                  @click="onVisits"
                >
                  游客访问
                </el-button>
              </template>
              <WxScan v-else :type="isRegister" @back="turnTo" />
              <div class="flex items-center text-[var(--color-1)] font-400">
                点击登录即表示已阅读并接受
                <el-button
                  link
                  class="gala-button font-400"
                >
                  <router-link to="/agreement" class="color-[var(--color-2)] font-400" target="_blank" rel="noopener noreferrer">
                    用户协议
                  </router-link>
                </el-button>
                <span>与</span>
                <el-button
                  link
                  class="gala-button font-400"
                >
                  <router-link to="/privacy" class="color-[var(--color-2)] font-400" target="_blank" rel="noopener noreferrer">
                    隐私条款
                  </router-link>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss">
.login-dialog {
  &.gala-dialog {
    // padding-right: 0;
    padding: 0;
  }
  .el-dialog__header {
    display: none;
  }
  &.gala-dialog .el-dialog__body {
    padding: 0;
  }
  &__close {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--color-grey-1);
    background: var(--color-1);
    font-size: 20px;
    color: var(--bright-color);
    cursor: pointer;
    position: absolute;
    top: -16px;
    right: -70px;
    &:hover {
      background:var(--hover-bg-color);
    }
  }
  &__left {
    flex: 1;
    width: 0;
    height: 100%;
    border-radius: 16px 0 0 16px;
    background: url("@/assets/img/dialog-bg.jpg") no-repeat;
    background-size: cover;
    box-sizing: border-box;
  }
  &__tooltip {
    padding: 8px;
    border-radius: 8px;
    background: #000000e6;
    color:var(--tooltip-color);
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    top: 21px;
    right: 56px;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 6px solid #000000e6;
      font-size: 0;
      line-height: 0;
      transform: rotate(90deg);
      position: absolute;
      top: 50%;
      right: -7px;
      margin-top: -3px;
    }
  }
}
</style>
