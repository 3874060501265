import { defineComponent, computed, openBlock, createBlock, unref, createSlots, withCtx, createElementBlock, createElementVNode, toDisplayString, createTextVNode, createVNode, pushScopeId, popScopeId } from "vue";
import { ElPopover, ElSpace } from "element-plus";
const _withScopeId = (n) => (pushScopeId("data-v-3dfb36c5"), n = n(), popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  "cursor-pointer": "",
  flex: "",
  "justify-center": "",
  "items-center": "",
  "w-32px": "",
  "h-32px": "",
  "border-rounded-full": "",
  text: "20px white",
  "font-600": "",
  class: "hover bg-[var(--color-1)]"
};
const _hoisted_2 = { class: "gala-header__avatar px-8px" };
const _hoisted_3 = { class: "py-16px px-8px flex justify-center items-center" };
const _hoisted_4 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("div", {
  class: "flex justify-center items-center w-32px h-32px border-rounded-full bg-[var(--color-1)]",
  text: "20px white"
}, " G ", -1));
const _hoisted_5 = { class: "text-[var(--color-1)] pl-12px flex-1 w-0" };
const _hoisted_6 = { class: "ellipsis" };
const _hoisted_7 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("i", {
  class: "ds-iconf-logout-line",
  title: "退出登录"
}, null, -1));
const _hoisted_8 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("a", {
  href: "/pay",
  class: "gala-header__avatar-btn relative h-40px mb-20px mx-8px flex items-center justify-center text-16px font-600 rounded-100px color-[var(--bright-color)] hover:op-80 cursor-pointer"
}, " 升级会员权益 ", -1));
const _hoisted_9 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("a", {
  href: "/collection",
  "px-8px": "",
  "h-28px": "",
  flex: "",
  "items-center": "",
  "border-rd-4px": "",
  class: "text-[var(--color-1)] hover:bg-[var(--color-grey-5)]"
}, [
  /* @__PURE__ */ createElementVNode("i", {
    "mr-8px": "",
    class: "ds-icon-stars",
    title: "我的收藏"
  }),
  /* @__PURE__ */ createTextVNode(" 我的收藏 ")
], -1));
const _hoisted_10 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("a", {
  href: "/invite",
  flex: "",
  "items-center": "",
  "px-8px": "",
  "h-28px": "",
  "border-rd-4px": "",
  class: "text-[var(--color-1)] hover:bg-[var(--color-grey-5)]"
}, [
  /* @__PURE__ */ createElementVNode("span", null, [
    /* @__PURE__ */ createElementVNode("i", {
      "mr-8px": "",
      class: "ds-icon-influencer-line"
    }),
    /* @__PURE__ */ createTextVNode("邀好友，领会员 ")
  ])
], -1));
const _hoisted_11 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("a", {
  href: "/user/info",
  "px-8px": "",
  "h-28px": "",
  flex: "",
  "items-center": "",
  "border-rd-4px": "",
  class: "text-[var(--color-1)] hover:bg-[var(--color-grey-5)]"
}, [
  /* @__PURE__ */ createElementVNode("i", {
    "mr-8px": "",
    class: "ds-icon-pre-text",
    title: "个人信息"
  }),
  /* @__PURE__ */ createTextVNode(" 个人信息 ")
], -1));
const _hoisted_12 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("a", {
  href: "/user/record",
  "px-8px": "",
  "h-28px": "",
  flex: "",
  "items-center": "",
  "border-rd-4px": "",
  class: "text-[var(--color-1)] hover:bg-[var(--color-grey-5)]"
}, [
  /* @__PURE__ */ createElementVNode("i", {
    "mr-8px": "",
    class: "ds-icon-article-time",
    title: "购买记录"
  }),
  /* @__PURE__ */ createTextVNode(" 购买记录 ")
], -1));
const _sfc_main = /* @__PURE__ */ defineComponent({
  __name: "Avatar",
  props: {
    userInfo: {}
  },
  emits: ["login", "logout"],
  setup(__props) {
    const props = __props;
    const isLogin = computed(() => {
      var _a;
      return Boolean((_a = props.userInfo) == null ? void 0 : _a.id);
    });
    return (_ctx, _cache) => {
      return openBlock(), createBlock(unref(ElPopover), {
        width: 290,
        "popper-style": "\n      --el-popover-padding: 0 0; --el-popover-border-radius: 8px;\n      --el-box-shadow-light: 0 0 30px 0 #0000001a;\n    ",
        "popper-class": "mt-[-5px]",
        placement: "bottom-start",
        "show-arrow": false
      }, createSlots({
        reference: withCtx(() => [
          isLogin.value ? (openBlock(), createElementBlock("div", _hoisted_1, " G ")) : (openBlock(), createElementBlock("button", {
            key: 1,
            class: "bg-[var(--color-1)] h-32 w-38 flex rounded-90px items-center justify-center cursor-pointer text-14px color-[var(--bright-color)] hover",
            onClick: _cache[0] || (_cache[0] = ($event) => _ctx.$emit("login"))
          }, " 登录 "))
        ]),
        _: 2
      }, [
        isLogin.value ? {
          name: "default",
          fn: withCtx(() => {
            var _a, _b;
            return [
              createElementVNode("div", _hoisted_2, [
                createElementVNode("div", _hoisted_3, [
                  _hoisted_4,
                  createElementVNode("div", _hoisted_5, [
                    createElementVNode("div", _hoisted_6, toDisplayString(((_a = _ctx.userInfo) == null ? void 0 : _a.phone) || ((_b = _ctx.userInfo) == null ? void 0 : _b.email)), 1)
                  ]),
                  createElementVNode("div", {
                    class: "text-[var(--color-1)] hover:text-[var(--color-2)] cursor-pointer",
                    onClick: _cache[1] || (_cache[1] = ($event) => _ctx.$emit("logout"))
                  }, [
                    _hoisted_7,
                    createTextVNode(" 退出 ")
                  ])
                ]),
                _hoisted_8,
                createVNode(unref(ElSpace), {
                  fill: "",
                  class: "py-8px w-full"
                }, {
                  default: withCtx(() => [
                    _hoisted_9,
                    _hoisted_10,
                    _hoisted_11,
                    _hoisted_12
                  ]),
                  _: 1
                })
              ])
            ];
          }),
          key: "0"
        } : void 0
      ]), 1024);
    };
  }
});
export {
  _sfc_main as default
};
