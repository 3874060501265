<script lang="ts" setup>
import type { FormInstance, FormRules } from 'element-plus'

const props = withDefaults(defineProps<{
  model: 'message' | 'password'
  params?: {
    openId: string
    unionId: string
  }
}>(), {
  model: 'message',
})

const router = useRouter()
const { hide, redirect } = useLoginDialog()
const { refetch: refetchUserInfo } = useUser()

const { model } = toRefs(props)
const formRef = ref<FormInstance>()
const form = reactive({
  phone: '',
  verifyCode: '',
  userName: '',
  password: '',
  promotionCode: '',
})
interface RuleForm {
  phone: string
  verifyCode: string
  userName: string
  password: string
}
const rules = reactive<FormRules<RuleForm>>({
  phone: [
    { required: true, message: '请输入手机号' },
    { pattern: /^[+|1]\d{10,12}$/, message: '手机号格式不正确' },
  ],
  verifyCode: [{ required: true, message: '请输入验证码' }],
  userName: [
    { required: true, message: '请输入手机号' },
    { pattern: /^[+|1]\d{10,12}$/, message: '手机号格式不正确' },
  ],
  password: [{ required: true, message: '请输入密码' }],
})

const codeTimeNum = ref(0)
const codeTimeInterval = ref<any>(null)

function clearCodeTimeNum() {
  codeTimeNum.value = 0
  clearInterval(codeTimeInterval.value)
}
const passwordVisible = ref(false)
function onPasswordVisible() {
  passwordVisible.value = !passwordVisible.value
}
const errorTip = ref('')
const isLoginIn = ref(false)
const { getPromotionCode, promotionCodeData } = useRegistrationPromotionCode()
// 从考古加跳转的隐藏邀请码输入框
const isFromKgj = computed(() => {
  return getPromotionCode() && promotionCodeData.value?.from === 'kgj'
})
function onSubmit() {
  if (isLoginIn.value)
    return false

  formRef?.value?.validate().then((e: boolean) => {
    if (e) {
      const param = {
        type: model.value === 'message' ? 2 : 1,
        phone: model.value === 'message' ? form.phone : form.userName,
        code: model.value === 'message' ? form.verifyCode : form.password,
        promotionCode: form.promotionCode || getPromotionCode(),
        openId: props.params?.openId,
        unionId: props.params?.unionId,
      }
      errorTip.value = ''
      isLoginIn.value = true

      login(param).then((res) => {
        window.collectEvent('config', {
          promotion_code: param.promotionCode,
        })
        window.collectEvent('beconEvent', res.isNewUser ? 'register' : 'login', {
          method: res.isNewUser ? '手机验证码注册' : param.type === 1 ? '手机号/账号登录' : '手机验证码登录',
          results: '成功',
          promotionCode: param.promotionCode,
        })

        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        res.isNewUser && window._agl && window._agl.push(['track', ['success', { t: 3 }]])

        refetchUserInfo()
        hide()
        if (redirect.value) {
          setTimeout(() => {
            router.push({ path: redirect.value })
          }, 300)
        }
      }).catch((err) => {
        errorTip.value = err.data?.fault.message ?? ''
      }).finally(() => {
        isLoginIn.value = false
      })
    }
  }).catch((e) => {
    const key = Object.keys(e)
    errorTip.value = e[key[0]][0].message
  })
}
function handleVerifyCode() {
  if (codeTimeNum.value > 0)
    return

  if (!form.phone) {
    errorTip.value = '请输入手机号'
    return
  }
  if (!/^1[3-9]\d{9}$/.test(form.phone)) {
    errorTip.value = '手机号格式不正确'
    return
  }
  errorTip.value = ''
  codeTimeNum.value = 60
  codeTimeInterval.value = setInterval(() => {
    codeTimeNum.value--
    if (codeTimeNum.value <= 0)
      clearCodeTimeNum()
  }, 1000)
  getVerifyCode(form.phone)
}
function getVerifyCode(phone: string) {
  getSmsCode(phone).catch((err) => {
    errorTip.value = err.data?.fault.message ?? ''
  })
}
watch(model, () => {
  errorTip.value = ''
})
</script>

<template>
  <ElForm
    ref="formRef"
    label-width="0"
    class="login-form w-full"
    :model="form"
    :rules="rules"
    :show-message="false"
    @submit.prevent
  >
    <div v-if="model === 'message'" class="flex flex-col gap-15px">
      <ElFormItem prop="phone">
        <ElInput
          v-model="form.phone"
          size="large"
          placeholder="请输入手机号"
          class="gala-input w-full"
        >
          <template #prefix>
            <i class="ds-icon-phone color-[var(--color-1)]" />
          </template>
        </ElInput>
      </ElFormItem>
      <ElFormItem prop="verifyCode">
        <ElInput
          v-model="form.verifyCode"
          size="large"
          placeholder="请输入验证码"
          class="gala-input w-full"
        >
          <template #prefix>
            <i class="ds-icon-result-clear color-[var(--color-1)]" />
          </template>
          <template #suffix>
            <div
              :class="codeTimeNum ? 'color-[var(--color-grey-4)] cursor-not-allowed' : 'color-[var(--color-2)] cursor-pointer'"
              class=""
              @click="handleVerifyCode"
            >
              {{ codeTimeNum ? `${codeTimeNum}秒后重发` : '获取验证码' }}
            </div>
          </template>
        </ElInput>
      </ElFormItem>
    </div>
    <div v-if="model === 'password'" class="flex flex-col gap-15px">
      <ElFormItem prop="userName">
        <ElInput
          v-model="form.userName"
          size="large"
          placeholder="请输入手机号"
          class="gala-input w-full"
        >
          <template #prefix>
            <i class="ds-icon-newzhanghao color-[var(--color-1)]" />
          </template>
        </ElInput>
      </ElFormItem>
      <ElFormItem prop="password">
        <ElInput
          v-model="form.password"
          size="large"
          placeholder="请输入密码"
          class="gala-input w-full py-1px"
          :type="passwordVisible ? 'text' : 'password'"
        >
          <template #prefix>
            <i class="ds-icon-newmima color-[var(--color-1)]" />
          </template>
          <template #suffix>
            <i :class="passwordVisible ? 'ds-icon-display-line' : 'ds-icon-huaban'" class="cursor-pointer" @click="onPasswordVisible" />
          </template>
        </ElInput>
      </ElFormItem>
    </div>
    <ElFormItem v-if="!isFromKgj && !params?.openId" prop="promotionCode" class="mt-15px">
      <ElInput
        v-model="form.promotionCode"
        size="large"
        placeholder="请输入邀请码（选填)"
        class="gala-input w-full"
      >
        <template #prefix>
          <i class="ds-icon-influencer-line color-[var(--color-1)]" />
        </template>
      </ElInput>
    </ElFormItem>
    <ElFormItem>
      <div class="h-20px w-full pb-10px color-[var(--color-4)] leading-20px">
        <template v-if="errorTip">
          <i class="ds-icon-mentionsolid ml-2 mr-6 text-12" /> {{ errorTip }}
        </template>
      </div>
      <button
        class="login-form__btn text-20"
        :class="{ disabled: isLoginIn }"
        data-popup_name="登录注册弹窗"
        @click="onSubmit"
      >
        登录
      </button>
    </ElFormItem>
  </ElForm>
</template>

<style lang="scss">
.login-form {
  .el-form-item {
    margin-bottom: 10px;
  }
  &__btn {
    display: flex;
    height: 48px;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 90px;
    background: var(--color-1);
    cursor: pointer;
    color: var(--bright-color);
    &:hover {
      background: var(--hover-bg-color);
    }
    &.disabled {
      opacity: 0.6;
      background: var(--color-1);
      cursor: not-allowed;
    }
  }
  .el-form-item.is-error .el-input__wrapper,
  .el-form-item.is-error .el-textarea__inner,
  .el-form-item.is-error .el-select__wrapper {
    box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color))
      inset;
  }
  .el-form-item.is-error .el-input__wrapper:hover,
  .el-form-item.is-error .el-input__wrapper:focus,
  .el-form-item.is-error .el-input__wrapper.is-focus,
  .el-form-item.is-error .el-textarea__inner:hover,
  .el-form-item.is-error .el-textarea__inner:focus,
  .el-form-item.is-error .el-textarea__inner.is-focus,
  .el-form-item.is-error .el-select__wrapper:hover,
  .el-form-item.is-error .el-select__wrapper:focus,
  .el-form-item.is-error .el-select__wrapper.is-focus {
    box-shadow: 0 0 0 1px var(--color-1) inset;
  }
}
</style>
