export interface LoginParams {
  username: string
  password: string
}
// TODO：先把航海加的代码拿过来，后续再优化
export function useWxLogin() {
  const { refetch: refetchUserInfo } = useUser()
  const { visible, hide, redirect } = useLoginDialog()
  const router = useRouter()

  const isBack = ref(true)

  interface QrCodeDataTypes {
    ticket: string
    expireSeconds: number // 二维码有效时间，单位为秒
    url: string // 二维码图片解析后的地址，开发者可根据该地址自行生成需要的二维码图片
  }
  interface QrCodetypes {
    loading: boolean
    data: QrCodeDataTypes
    openId: string
    unionId: string
    timesOut: boolean
  }
  function initQrcodeParams() {
    return {
      loading: false,
      data: {
        ticket: '',
        expireSeconds: 0,
        url: '',
      },
      openId: '',
      unionId: '',
      timesOut: false,
    }
  }
  const qrcodeData = ref<QrCodetypes>(initQrcodeParams())
  const polling = ref<any>(null) // 用来存储轮询的interval ID
  // 重置qrcodeData
  function resetQrcodeData() {
    qrcodeData.value = initQrcodeParams()
    fetchQrCode()
  }
  async function fetchOfficialStatus() {
    getOfficialStatus(qrcodeData.value.data?.ticket).then((res) => {
      const { id, openId, unionId } = res || {}
      // // 假设返回的数据结构中有wechatId字段
      if (openId && openId && !id) {
        qrcodeData.value.openId = openId
        qrcodeData.value.unionId = unionId
        isBack.value = false
        stopPolling() // 停止轮询
      }
      if (id) {
        stopPolling() // 停止轮询
        refetchUserInfo()
        hide()
        if (redirect.value) {
          setTimeout(() => {
            router.push({ path: redirect.value })
          }, 300)
        }
      }
    }).catch(() => {
      stopPolling() // 如果超过过期时间，停止轮询
    })
  }
  function stopPolling() {
    if (polling.value) {
      clearInterval(polling.value)
      polling.value = null
    }
  }
  async function fetchQrCode() {
    qrcodeData.value.loading = true
    getQrCode().then((res) => {
      qrcodeData.value.data = res
      qrcodeData.value.loading = false
      // 开始轮询
      const startTime = Date.now()
      polling.value = setInterval(async () => {
        const currentTime = Date.now()
        const elapsedTime = (currentTime - startTime) / 1000 // 转换为秒

        if (elapsedTime > qrcodeData.value.data.expireSeconds) {
          qrcodeData.value.timesOut = true
          stopPolling() // 如果超过过期时间，停止轮询
        }
        else {
          await fetchOfficialStatus() // 否则，继续轮询
        }
      }, 1000)
    }).catch(() => {
      stopPolling() // 如果超过过期时间，停止轮询
    }).finally(() => {
      qrcodeData.value.loading = false
    })
  }
  watch(() => visible.value, (val) => {
    if (!val)
      stopPolling() // 如果超过过期时间，停止轮询

    else
      resetQrcodeData()
  })

  return {
    isBack,
    open,
    fetchQrCode,
    qrcodeData,
    stopPolling,
    resetQrcodeData,
  }
}
