export function useExpiredDialog() {
  // Globa State
  const expiredDialogState = useState('expired-dialog', () => ({
    visible: false,
  }))

  return {
    visible: computed(() => expiredDialogState.value.visible),

    show: () => {
      expiredDialogState.value.visible = true
    },
    hide: () => {
      expiredDialogState.value.visible = false
    },
  }
}
