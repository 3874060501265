<script setup lang="ts">
import QrcodeVue from 'qrcode.vue'
import LoginForm from './LoginForm.vue'

const props = defineProps<{
  type: boolean
}>()
const emits = defineEmits(['back'])
const { resetQrcodeData, qrcodeData, isBack, stopPolling } = useWxLogin()
onMounted(() => {
  resetQrcodeData()
})
onBeforeUnmount(() => {
  stopPolling()
})
watch(() => props.type, (val) => {
  isBack.value = !val
})
watch(isBack, (val) => {
  emits('back', val)
  val && resetQrcodeData()
})
</script>

<template>
  <div v-if="isBack">
    <div v-loading="qrcodeData.loading" class="relative mx-auto mb-34px mt-26px box-border h-240px w-240px flex items-center justify-center border-1 border-[#eee] rounded-16px border-solid px-30px" @click="resetQrcodeData">
      <QrcodeVue :value="qrcodeData?.data?.url" :size="210" />
      <div v-if="qrcodeData.timesOut" class="absolute left-0 top-0 h-full w-full flex flex-col cursor-pointer items-center justify-center gap-16px rounded-16px bg-black/60 text-center text-14px text-[var(--bright-color)] backdrop-blur-2">
        <span>
          <i class="ds-icon-turn text-45px text-[var(--bright-color)]" />
        </span>
        <span>
          刷新二维码
        </span>
      </div>
    </div>
    <div class="mb-10px text-center text-[var(--color-1)]">
      微信扫码<i class="ds-icon-weixin-solid ml-4 text-15px text-#00C800" /><span class="mx-4 text-#222 font-500">关注公众号</span>后即可登录/注册
    </div>
  </div>
  <LoginForm v-else class="mb-20px" model="message" :params="qrcodeData" />
</template>

<style lang="scss">

</style>
