import { defineComponent, openBlock, createBlock, unref, normalizeStyle, withCtx, createVNode, createElementVNode, renderSlot, pushScopeId, popScopeId, createTextVNode } from "vue";
import _imports_0 from "../assets/pay-enter-btn.4420af60.png";
import { ElHeader, ElLink, ElSpace, ElPopover } from "element-plus";
import { useWindowScroll } from "@vueuse/core";
import Avatar from "./Avatar.vue.js";
import Search from "./Search.vue.js";
import Menu from "./Menu.vue.js";
import "element-plus/es/components/header/style/css";
import "element-plus/es/components/link/style/css";
import "element-plus/es/components/option/style/css";
import "element-plus/es/components/popover/style/css";
import "element-plus/es/components/select/style/css";
import "element-plus/es/components/space/style/css";
const _withScopeId = (n) => (pushScopeId("data-v-11a7b89c"), n = n(), popScopeId(), n);
const _hoisted_1 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("img", {
  style: { "height": "30px" },
  class: "cursor-pointer",
  src: "//cdn-static.glodastory.com/ds-gala-front-app-web/assets/logo-DfRFpu4_.svg",
  alt: "glodastory"
}, null, -1));
const _hoisted_2 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("a", {
  href: "https://u177jt193qg.feishu.cn/docx/DtCXdeWwioM1aex7EpPchRNrnjf?from=from_copylink",
  target: "_blank",
  class: "flex gap-8px whitespace-nowrap cursor-pointer items-center color-[var(--color-1)] group hover:color-[var(--color-2)]"
}, [
  /* @__PURE__ */ createElementVNode("i", { class: "ds-icon-help text-15px text-[var(--color-grey-1)] group-hover:color-[var(--color-2)]" }),
  /* @__PURE__ */ createTextVNode("帮助 ")
], -1));
const _hoisted_3 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("div", { class: "guide-header__service whitespace-nowrap h-32px flex items-center justify-center cursor-pointer text-14px font-600 color-[var(--color-2)] hover:op-80 gap-8px" }, [
  /* @__PURE__ */ createElementVNode("i", { class: "ds-icon-service" }),
  /* @__PURE__ */ createTextVNode(" 联系客服 ")
], -1));
const _hoisted_4 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("div", { class: "px-24px py-32px flex flex-col color-[var(--color-1)] items-center justify-center" }, [
  /* @__PURE__ */ createElementVNode("div", { class: "w-220px h-220px" }, [
    /* @__PURE__ */ createElementVNode("img", {
      src: "//cdn-static.glodastory.com/ds-gala-front-app-web/assets/customer-25h4b3Y3.png",
      class: "w-full h-full"
    })
  ]),
  /* @__PURE__ */ createElementVNode("div", { class: "pt-20px text-16px font-500" }, " 扫码添加客服企微 ")
], -1));
const _hoisted_5 = /* @__PURE__ */ _withScopeId(() => /* @__PURE__ */ createElementVNode("a", {
  href: "/pay",
  class: "w-96px hover:op-80"
}, [
  /* @__PURE__ */ createElementVNode("img", {
    src: _imports_0,
    class: "w-full"
  })
], -1));
const _hoisted_6 = ["href"];
const _sfc_main = /* @__PURE__ */ defineComponent({
  __name: "Header",
  props: {
    userInfo: {},
    newVersionLink: {}
  },
  emits: ["search", "login", "logout"],
  setup(__props) {
    const { y } = useWindowScroll();
    return (_ctx, _cache) => {
      return openBlock(), createBlock(unref(ElHeader), {
        flex: "",
        "items-center": "",
        "w-100vw": "",
        height: "56px",
        "transition-300": "",
        style: normalizeStyle({ "padding": "0 32px", "background-color": unref(y) > 5 ? "rgba(255,255,255,0.9)" : "rgba(255,255,255,0.3)" })
      }, {
        default: withCtx(() => [
          createVNode(unref(ElLink), {
            href: "/",
            class: "mr-24px",
            underline: false
          }, {
            default: withCtx(() => [
              _hoisted_1
            ]),
            _: 1
          }),
          createVNode(Menu, {
            "user-info": _ctx.userInfo,
            "flex-1": ""
          }, null, 8, ["user-info"]),
          createVNode(unref(ElSpace), { size: 16 }, {
            default: withCtx(() => [
              createVNode(Search, {
                onSearch: _cache[0] || (_cache[0] = (k, p) => _ctx.$emit("search", k, p))
              }),
              _hoisted_2,
              createVNode(unref(ElPopover), {
                placement: "bottom-start",
                "popper-style": { zIndex: 4e3 },
                width: "auto",
                trigger: "hover",
                "show-arrow": false,
                "popper-class": "gala-popper"
              }, {
                reference: withCtx(() => [
                  _hoisted_3
                ]),
                default: withCtx(() => [
                  _hoisted_4
                ]),
                _: 1
              }),
              _hoisted_5,
              createElementVNode("a", {
                href: _ctx.newVersionLink,
                target: "_blank",
                class: "hover px-16px h-32px whitespace-nowrap flex items-center rounded-90px color-white bg-[var(--color-1)]"
              }, " 体验新版 ", 8, _hoisted_6),
              renderSlot(_ctx.$slots, "avatar", {}, void 0, true),
              createVNode(Avatar, {
                "user-info": _ctx.userInfo,
                onLogin: _cache[1] || (_cache[1] = ($event) => _ctx.$emit("login")),
                onLogout: _cache[2] || (_cache[2] = ($event) => _ctx.$emit("logout"))
              }, null, 8, ["user-info"])
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["style"]);
    };
  }
});
export {
  _sfc_main as default
};
