<script setup lang="ts">
// import { useUnexpiredDialogStore } from '@/stores/user'

const { visible, hide } = useExpiredDialog()

// const useUnexpireStore = useUnexpiredDialogStore()
function onClose() {
  hide()
}
const userRouter = useRouter()
function toInvite() {
  userRouter.push({ path: '/invite' })
  // useUnexpireStore.setCloseLoginDialogReload(false)
  onClose()
}
function toPay() {
  userRouter.push({ path: '/pay' })
  // useUnexpireStore.setCloseLoginDialogReload(false)
  onClose()
}

function onOpened() {
  window.collectEvent('page_popup', {
    popup_name: '会员过期提醒弹窗',
    page_title: document.title,
  })
}
</script>

<template>
  <el-dialog
    v-model="visible"
    :show-close="false"
    width="400"
    :z-index="4000"
    :close-on-click-modal="false"
    class="gala-dialog unexpired-dialog"
    @opened="onOpened"
  >
    <div class="relative relative h-479 flex flex-col items-center justify-center">
      <div class="absolute right-24px top-24px h-28px w-28px flex cursor-pointer items-center justify-center text-14px hover:color-[var(--color-1)]" @click="onClose">
        <i class="ds-icon-close-line" />
      </div>
      <img class="w-187px" src="@/assets/img/unexpired.png" alt="">
      <div class="pt-24px text-20px color-[var(--color-1)] font-500">
        您的会员已过期
      </div>
      <div class="pt-12px text-16px color-[var(--color-1)]">
        邀请好友注册可
        <span class="color-[var(--color-2)]">免费领取3天会员！</span>
      </div>
      <div class="unexpired-dialog__btn mt-40px bg-[var(--color-1)] color-[var(--bright-color)]" data-popup_name="会员过期提醒弹窗" @click="toInvite">
        邀请好友
      </div>
      <div class="unexpired-dialog__btn mt-16px bg-[var(--bright-color)] color-[var(--color-1)]" data-popup_name="会员过期提醒弹窗" @click="toPay">
        付费开通
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss">
.unexpired-dialog {
  &.gala-dialog {
    background: linear-gradient(180deg, #6234cf4d 0%, #6234cf00 39.45%), #FFF;
    padding: 0;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  &__btn {
    width: 252px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid var(--color-1);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    height: 44px;
    border-radius: 90px;
    &:hover {
      background: var(--hover-bg-color);
      color: var(--bright-color);
      border: 0;
    }
  }
}
</style>
