<script setup lang="ts">
const props = defineProps<{
  value: boolean
  isRegister: boolean
}>()
const emit = defineEmits(['change', 'return'])
const type = useVModel(props, 'value')
const isNew = useVModel(props, 'isRegister')
function changeType() {
  type.value = !type.value
  emit('change', type.value)
}
const tips = computed(() => !props.value ? '扫码登录在这里' : '短信或密码登录在这里')
</script>

<template>
  <div v-if="!isRegister" class="relative flex justify-end">
    <div class="h-78px w-78px cursor-pointer rounded-tr-16 hover:text-[var(--color-2)]" @click="changeType">
      <svg v-if="!type" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 78" class="design-iconfont">
        <g clip-path="url(#tu68i7ntj__clip0_82_10317474)">
          <path d="M0 0H78V78L0 0Z" fill="#E9ECF1" />
          <g clip-path="url(#tu68i7ntj__clip1_82_10317474)">
            <mask id="tu68i7ntj__w4de076cua" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="-1" width="78" height="79">
              <path d="M0 -0.000488281L78 -0.000488281L78 77.9995L0 -0.000488281Z" fill="#EEE" />
            </mask>
            <g mask="url(#tu68i7ntj__w4de076cua)">
              <path d="M62.853 14.9995H40.3104V19.6438H62.853V14.9995ZM34.5776 14.9995H21.5266V19.6438H34.5776V14.9995ZM16.7423 14.9995H12V19.7383H16.7423V14.9995ZM62.853 32.9787H40.3104V37.6265H62.853V32.9787ZM63 14.9995H58.3522V37.5251H63V14.9995ZM44.9582 14.9995H40.3104V37.5251H44.9582V14.9995ZM55.1428 23.4943H48.8151V29.8172H55.1428V23.4943ZM34.5776 19.6438H30.0523V24.4105H34.5776V19.6438ZM21.5301 19.6438H16.5673V28.4148H21.5301V19.6438ZM16.7423 24.4105H12V32.7094H16.7423V24.4105ZM29.8808 27.9672H21.5266V33.9405H29.8808V27.9672ZM25.205 32.7059H16.7073V37.5216H25.205V32.7059ZM34.5496 32.8563H29.8808V37.5216H34.5496V32.8563ZM63 42.1799H58.3522V52.7031H63V42.1799ZM58.3417 51.7133H53.3124V56.7424H58.3417V51.7133ZM63 56.543H58.3417V65.9995H63V56.543ZM53.5959 42.1799H39.2849V47.9573H53.5959V42.1799ZM50.1135 47.4432H44.1427V52.9339H50.1135V47.4432ZM45.2557 51.7133H39.2849V61.2678H45.2557V51.7133ZM49.8895 56.543H44.1427V65.9995H49.8895V56.543ZM54.6738 60.0123H49.095V65.9995H54.6738V60.0123ZM31.0917 42.1799H20.4137V51.7133H31.0917V42.1799ZM17.8588 42.1799H12V48.0343H17.8588V42.1799ZM35.6906 46.9466H29.8318V57.5642H35.6906V46.9466ZM26.3074 50.3774H20.4137V56.4906H26.3074V50.3774ZM35.6906 60.0123H29.8318V65.9995H35.6906V60.0123ZM21.5966 61.2678H12V65.9995H21.5966V61.2678ZM16.7983 56.4906H12V62.268H16.7983V56.4906Z" fill="currentColor" />
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="tu68i7ntj__clip0_82_10317474">
            <path d="M0 0H62C70.8366 0 78 7.16344 78 16V78H0V0Z" fill="#fff" />
          </clipPath>
          <clipPath id="tu68i7ntj__clip1_82_10317474">
            <path fill="#fff" transform="matrix(1 0 0 -1 4 73.9995)" d="M0 0H70V70H0z" />
          </clipPath>
        </defs>
      </svg>
      <svg v-else fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 78" class="design-iconfont">
        <g clip-path="url(#f8x3fj59z__clip0_2573_125460)">
          <path d="M0 0H78V78L0 0Z" fill="#EEE" />
          <g clip-path="url(#f8x3fj59z__clip1_2573_125460)">
            <mask id="f8x3fj59z__6zvkjlot5a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="78" height="78">
              <path d="M0 0H78V78L0 0Z" fill="#E3E7F0" />
            </mask>
            <g mask="url(#f8x3fj59z__6zvkjlot5a)">
              <path d="M27 21.1131C27 20.1251 27.1958 19.2064 27.5873 18.3568C27.9788 17.5071 28.5171 16.7563 29.2023 16.1043C29.8874 15.4523 30.69 14.9385 31.6101 14.5631C32.5302 14.1877 33.5188 14 34.5759 14H55.4241C56.4812 14 57.4698 14.1877 58.3899 14.5631C59.31 14.9385 60.1126 15.4523 60.7977 16.1043C61.4829 16.7563 62.0212 17.5071 62.4127 18.3568C62.8042 19.2064 63 20.1251 63 21.1131V60.8277C63 61.8156 62.8042 62.7442 62.4127 63.6136C62.0212 64.483 61.4829 65.2437 60.7977 65.8957C60.1126 66.5477 59.31 67.0615 58.3899 67.4369C57.4698 67.8123 56.4812 68 55.4241 68H34.5759C33.5188 68 32.5302 67.8123 31.6101 67.4369C30.69 67.0615 29.8874 66.5477 29.2023 65.8957C28.5171 65.2437 27.9788 64.483 27.5873 63.6136C27.1958 62.7442 27 61.8156 27 60.8277V21.1131ZM31.7569 57.9824H58.3018V24.0176H31.7569V57.9824ZM45.0294 22.3579C45.8515 22.3579 46.5661 22.0615 47.1729 21.4687C47.7798 20.876 48.0832 20.1449 48.0832 19.2755C48.0832 18.4457 47.7798 17.7245 47.1729 17.112C46.5661 16.4994 45.8515 16.1932 45.0294 16.1932C44.168 16.1932 43.4437 16.4994 42.8564 17.112C42.2692 17.7245 41.9755 18.4457 41.9755 19.2755C41.9755 20.1449 42.2692 20.876 42.8564 21.4687C43.4437 22.0615 44.168 22.3579 45.0294 22.3579ZM40.3312 63.0209C40.3312 63.337 40.429 63.584 40.6248 63.7618C40.8206 63.9396 41.0359 64.0285 41.2708 64.0285H48.7292C48.925 64.0285 49.1305 63.9396 49.3458 63.7618C49.5612 63.584 49.6688 63.337 49.6688 63.0209C49.6688 62.7047 49.571 62.4577 49.3752 62.2799C49.1794 62.1021 48.9641 62.0132 48.7292 62.0132H41.2708C41.0359 62.0132 40.8206 62.1021 40.6248 62.2799C40.429 62.4577 40.3312 62.7047 40.3312 63.0209Z" fill="currentColor" />
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="f8x3fj59z__clip0_2573_125460">
            <path d="M0 0H62C70.8366 0 78 7.16344 78 16V78H0V0Z" fill="#fff" />
          </clipPath>
          <clipPath id="f8x3fj59z__clip1_2573_125460">
            <path fill="#fff" transform="matrix(1 0 0 -1 4 74)" d="M0 0H70V70H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="login-dialog__tooltip">
      {{ tips }}
    </div>
  </div>
  <div v-else class="relative h-60px w-full b-0 b-b-1 b-[#eee] border-solid text-center text-18px text-#140A2C font-600 lh-60px">
    <div class="absolute left-20px top-50% cursor-pointer text-14px text-#4E5969 -translate-y-1/2" @click="isNew = !isNew">
      <i class="ds-icon-left !text-14 !text-#666" />
    </div>
    绑定手机号
  </div>
</template>

<style lang="scss">

</style>
