import { defineComponent, ref, openBlock, createBlock, unref, withKeys, withCtx, createVNode, createElementBlock, Fragment, renderList, createElementVNode, createCommentVNode } from "vue";
import { ElInput, ElSelect, ElOption } from "element-plus";
import "element-plus/es/components/input/style/css";
import "element-plus/es/components/option/style/css";
import "element-plus/es/components/select/style/css";
const _hoisted_1 = { class: "w-12px h-12px text-12px mr-8px flex items-center justify-center" };
const _sfc_main = /* @__PURE__ */ defineComponent({
  __name: "Search",
  emits: ["search"],
  setup(__props, { emit: __emit }) {
    const emit = __emit;
    const searchOptions = [
      {
        path: "/analysis/products",
        name: "AnalysisProducts",
        label: "找商品"
      },
      {
        path: "/analysis/shop",
        name: "AnalysisShop",
        label: "找小店"
      },
      {
        path: "/analysis/blogger",
        name: "AnalysisBlogger",
        label: "找达人"
      },
      {
        path: "/analysis/video",
        name: "AnalysisVideo",
        label: "找视频"
      }
    ];
    const analysisSearch = ref("");
    const analysisRouterValue = ref(searchOptions[0].path);
    function onAnalysisSearch() {
      emit("search", analysisSearch.value, analysisRouterValue.value);
    }
    return (_ctx, _cache) => {
      return openBlock(), createBlock(unref(ElInput), {
        modelValue: analysisSearch.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => analysisSearch.value = $event),
        placeholder: "请输入内容",
        class: "gala-input-prepend",
        onKeyup: withKeys(onAnalysisSearch, ["enter"])
      }, {
        prepend: withCtx(() => [
          createVNode(unref(ElSelect), {
            modelValue: analysisRouterValue.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => analysisRouterValue.value = $event),
            style: { "width": "110px" },
            "popper-class": "gala-select-popper gala-header-select-popper"
          }, {
            default: withCtx(() => [
              (openBlock(), createElementBlock(Fragment, null, renderList(searchOptions, (item) => {
                return createVNode(unref(ElOption), {
                  key: item.path,
                  label: item.label,
                  value: item.path
                }, null, 8, ["label", "value"]);
              }), 64))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        append: withCtx(() => [
          createElementVNode("div", _hoisted_1, [
            analysisSearch.value ? (openBlock(), createElementBlock("i", {
              key: 0,
              class: "ds-icon-closesolid clear",
              onClick: _cache[1] || (_cache[1] = ($event) => analysisSearch.value = "")
            })) : createCommentVNode("", true)
          ]),
          createElementVNode("i", {
            class: "ds-icon-search",
            onClick: onAnalysisSearch
          })
        ]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
});
export {
  _sfc_main as default
};
