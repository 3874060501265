const routes = [
  {
    path: "/analysis",
    name: "Analysis",
    meta: { title: "基础数据", isMenu: true, pageTitle: "商品库-Gloda | TikTok专业数据分析工具" },
    redirect: "/analysis/products",
    children: [
      {
        path: "/analysis/products",
        name: "AnalysisProducts",
        meta: { title: "商品库", isMenuPopper: true, icon: "ds-icon-shopping1", pageTitle: "商品库-Gloda | TikTok专业数据分析工具", desc: "找在TikTok卖爆的商品" }
      },
      {
        path: "/analysis/shop",
        name: "AnalysisShop",
        meta: { title: "小店库", isMenuPopper: true, icon: "ds-icon-store-plan-fill", pageTitle: "小店库-Gloda | TikTok专业数据分析工具", desc: "找优质商家小店" }
      },
      {
        path: "/analysis/blogger",
        name: "AnalysisBlogger",
        meta: { title: "达人库", isMenuPopper: true, icon: "ds-icon-headlovesolid", pageTitle: "达人库-Gloda | TikTok专业数据分析工具", desc: "搜索达人，多个维度数据分析" }
      },
      {
        path: "/analysis/video",
        name: "AnalysisVideo",
        meta: { title: "视频库", isMenuPopper: true, icon: "ds-icon-ds-icon-project-list-video-fill", pageTitle: "视频库-Gloda | TikTok专业数据分析工具", desc: "打造爆款视频 " }
      },
      {
        path: "/analysis/products/detail",
        name: "AnalysisProductsDetail",
        meta: { isScrollSmooth: true, pageTitle: "商品详情-Gloda | TikTok专业数据分析工具" }
      },
      {
        path: "/analysis/shop/detail",
        name: "AnalysisShopDetail",
        meta: { isScrollSmooth: true, pageTitle: "小店详情-Gloda | TikTok专业数据分析工具" }
      },
      {
        path: "/analysis/blogger/detail",
        name: "AnalysiBloggerDetail",
        meta: { isScrollSmooth: true, pageTitle: "达人详情-Gloda | TikTok专业数据分析工具" }
      },
      {
        path: "/analysis/video/detail",
        name: "AnalysisVideoDetail",
        meta: { isScrollSmooth: true, pageTitle: "视频详情-Gloda | TikTok专业数据分析工具" }
      }
    ]
  },
  {
    path: "/ranking",
    name: "Ranking",
    redirect: "/analysis/products/hotSale",
    meta: { title: "TikTok榜单", isMenu: true, pageTitle: "商品库-Gloda | TikTok专业数据分析工具" },
    children: [
      {
        path: "/analysis/products/hotSale",
        meta: { title: "商品榜", icon: "ds-icon-shopping1", isMenuPopper: true, pageTitle: "商品库-Gloda | TikTok专业数据分析工具", desc: "找在TikTok卖爆的商品" },
        children: [{
          path: "/analysis/products/hotSale",
          name: "RankingProductsHotSale",
          meta: { title: "热销商品榜", isMenuPopper: true, pageTitle: "热销商品榜-Gloda | TikTok专业数据分析工具" }
        }, {
          path: "/analysis/products/soldSoaring",
          name: "RankingProductsSoldSoaring",
          meta: { title: "飙升增长榜", isMenuPopper: true, pageTitle: "飙升增长榜-Gloda | TikTok专业数据分析工具" }
        }, {
          path: "/analysis/products/hotPush",
          name: "RankingProductsHotPush",
          meta: { title: "达人热推榜", isMenuPopper: true, pageTitle: "达人热推榜-Gloda | TikTok专业数据分析工具" }
        }]
      },
      {
        path: "/analysis/shop/hotSale",
        meta: { title: "小店榜", isMenuPopper: true, icon: "ds-icon-store-plan-fill", pageTitle: "商品库-Gloda | TikTok专业数据分析工具", desc: "找在TikTok卖爆的商品" },
        children: [{
          path: "/analysis/shop/hotSale",
          name: "RankingShopHotSale",
          meta: { title: "热销小店榜", isMenuPopper: true, pageTitle: "热销小店榜-Gloda | TikTok专业数据分析工具" }
        }, {
          path: "/analysis/shop/soldSoaring",
          name: "RankingShopSoldSoaring",
          meta: { title: "飙升增长榜", isMenuPopper: true, pageTitle: "飙升增长榜-Gloda | TikTok专业数据分析工具" }
        }, {
          path: "/analysis/shop/selfPromotion",
          name: "RankingShopSelfPromotion",
          meta: { title: "小店自推榜", isMenuPopper: true, pageTitle: "小店自推榜-Gloda | TikTok专业数据分析工具" }
        }, {
          path: "/analysis/shop/bloggerInteract",
          name: "RankingShopBloggerInteract",
          meta: { title: "达人合作榜", isMenuPopper: true, pageTitle: "达人合作榜-Gloda | TikTok专业数据分析工具" }
        }]
      },
      {
        path: "/analysis/blogger/increaseFans",
        meta: { title: "达人榜", isMenuPopper: true, icon: "ds-icon-headlovesolid", pageTitle: "商品库-Gloda | TikTok专业数据分析工具", desc: "找在TikTok卖爆的商品" },
        children: [{
          path: "/analysis/blogger/increaseFans",
          name: "RankingBloggerIncreaseFans",
          meta: { title: "达人涨粉榜", isMenuPopper: true, pageTitle: "达人涨粉榜-Gloda | TikTok专业数据分析工具" }
        }, {
          path: "/analysis/blogger/contentExposure",
          name: "RankingBloggerContentExposure",
          meta: { title: "内容曝光榜", isMenuPopper: true, pageTitle: "内容曝光榜-Gloda | TikTok专业数据分析工具" }
        }, {
          path: "/analysis/blogger/shopInteract",
          name: "RankingBloggerShopInteract",
          meta: { title: "店铺合作榜", isMenuPopper: true, pageTitle: "店铺合作榜-Gloda | TikTok专业数据分析工具" }
        }]
      },
      {
        path: "/analysis/video/hotInteraction",
        meta: { title: "视频榜", isMenuPopper: true, icon: "ds-icon-ds-icon-project-list-video-fill", pageTitle: "商品库-Gloda | TikTok专业数据分析工具", desc: "找在TikTok卖爆的商品" },
        children: [{
          path: "/analysis/video/hotInteraction",
          name: "RankingVideoHotInteraction",
          meta: { title: "高互动热榜", isMenuPopper: true, pageTip: "播放量大于1W的高互动率视频", pageTitle: "高互动热榜-Gloda | TikTok专业数据分析工具" }
        }, {
          path: "/analysis/video/hotDigg",
          name: "RankingVideoHotDigg",
          meta: { title: "低粉爆款榜", isMenuPopper: true, pageTip: "播放量大于等于1W或点赞量大于等于5k", pageTitle: "低粉爆款榜-Gloda | TikTok专业数据分析工具" }
        }]
      }
    ]
  },
  {
    path: "/marketing",
    name: "Marketing",
    redirect: "/marketing/overview",
    meta: { title: "市场研究", isMenu: true, isNewIcon: true, pageTitle: "市场研究-Gloda | TikTok专业数据分析工具" },
    children: [
      {
        path: "/marketing/overview",
        name: "MarketingOverview",
        meta: { title: "品类大盘", isMenu: true, pageTitle: "品类大盘-Gloda | TikTok专业数据分析工具" }
      },
      {
        path: "/marketing/segment",
        name: "MarketingSegment",
        meta: { title: "细分市场", isMenu: true, isNewIcon: true, pageTitle: "细分市场-Gloda | TikTok专业数据分析工具" }
      },
      {
        path: "/marketing/segment/detail",
        name: "MarketingSegmentDetail",
        meta: { title: "细分市场", pageTitle: "细分市场-Gloda | TikTok专业数据分析工具" }
      }
    ]
  },
  {
    path: "/creative",
    name: "Creative",
    meta: { title: "创意选品", pageTitle: "创意选品-Gloda | TikTok专业数据分析工具" },
    children: [
      {
        path: "/creative",
        name: "CreativeProducts",
        meta: { title: "创意选品", isMenu: true, isHotIcon: true, pageTitle: "创意选品-Gloda | TikTok专业数据分析工具" }
      },
      {
        path: "/creative/contrast",
        name: "CreativeProductsContrast",
        meta: { title: "创意选品对比", pageTitle: "选品对比-Gloda | TikTok专业数据分析工具" }
      }
    ]
  },
  {
    path: "/creator",
    name: "Creator",
    redirect: "/creator/bloggerLib/find",
    meta: { title: "建联工具", privilegeId: "4", isMenu: true, isNewIcon: true, pageTitle: "建联工具-Gloda | TikTok专业数据分析工具" },
    children: [
      {
        path: "/creator/bloggerLib",
        name: "CreatorBloggerLib",
        redirect: "/creator/bloggerLib/recommend",
        meta: { title: "达人广场", isMenuPopper: true, icon: "ds-icon-headlovesolid", pageTitle: "建联工具-Gloda | TikTok专业数据分析工具" },
        children: [
          // {
          //   path: '/creator/bloggerLib/ranking',
          //   name: 'CreatorBloggerLibRanking',
          //   meta: { title: '达人榜', isMenuPopper: true, pageTitle: '建联工具-Gloda | TikTok专业数据分析工具' },
          // },
          {
            path: "/creator/bloggerLib/find",
            name: "CreatorBloggerLibFind",
            meta: { title: "找达人", isMenuPopper: true, pageTitle: "建联工具-Gloda | TikTok专业数据分析工具" }
          }
        ]
      },
      {
        path: "/creator/bloggerMgmt",
        name: "CreatorBloggerMgmt",
        redirect: "/creator/bloggerMgmt/assets",
        meta: { title: "达人管理", isMenuPopper: true, icon: "ds-icon-usermaneger-fill", pageTitle: "建联工具-Gloda | TikTok专业数据分析工具" },
        children: [
          {
            path: "/creator/bloggerMgmt/collection",
            name: "CreatorBloggerMgmtCollection",
            meta: { title: "达人收藏", isMenuPopper: true, pageTitle: "建联工具-Gloda | TikTok专业数据分析工具" }
          }
        ]
      },
      {
        path: "/creator/linkTool",
        name: "CreatorLinkTool",
        redirect: "/creator/linkTool/emailMessages",
        meta: { title: "建联工具", isMenuPopper: true, icon: "ds-icon-newsSolid", pageTitle: "建联工具-Gloda | TikTok专业数据分析工具" },
        children: [
          {
            path: "/creator/linkTool/emailMessages",
            name: "CreatorLinkToolEmailMessages",
            meta: { title: "邮件消息", isMenuPopper: true, pageTitle: "建联工具-Gloda | TikTok专业数据分析工具" }
          },
          {
            path: "/creator/linkTool/emailInvitation",
            name: "CreatorLinkToolEmailInvitation",
            meta: { title: "邮件邀约", isMenuPopper: true, pageTitle: "建联工具-Gloda | TikTok专业数据分析工具" }
          },
          {
            path: "/creator/linkTool/createEmailInvitaion",
            name: "CreatorLinkToolCreateEmailInvitaion",
            meta: { title: "创建邮件", pageTitle: "建联工具-Gloda | TikTok专业数据分析工具" }
          }
        ]
      }
    ]
  },
  // {
  //   path: '/content',
  //   name: 'Content',
  //   meta: { title: 'TikTok资讯', pageTitle: 'TikTok热点_最新TikTok政策&资讯-GlodaStory', isMenu: true },
  // },
  {
    path: "/tools",
    name: "Tools",
    redirect: "/tools/bloggers",
    meta: { title: "工具", isMenu: true, pageTitle: "工具-Gloda | TikTok专业数据分析工具" },
    children: [{
      path: "/tools/bloggers",
      name: "ToolsBloggers",
      meta: { title: "橱窗达人检测", isMenu: true, pageTitle: "橱窗达人检测-Gloda | TikTok专业数据分析工具" }
    }]
  },
  {
    path: "/user/info",
    name: "UserInfo",
    meta: { pageTitle: "个人信息-Gloda | TikTok专业数据分析工具" }
  },
  {
    path: "/invite",
    name: "Invite",
    meta: { pageTitle: "邀好友，领会员-Gloda | TikTok专业数据分析工具" }
  },
  {
    path: "/pay",
    name: "Pay",
    meta: { pageTitle: "购买续费-Gloda | TikTok专业数据分析工具" }
  }
];
export {
  routes as default,
  routes
};
