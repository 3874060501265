import { defineComponent, computed, ref, watch, openBlock, createElementBlock, createVNode, unref, withCtx, Fragment, renderList, createBlock, createElementVNode, createTextVNode, toDisplayString, normalizeClass, createCommentVNode } from "vue";
import _imports_0 from "../assets/new.5c06ba3b.svg";
import _imports_1 from "../assets/hot.601a58ad.svg";
import { useRoute, useRouter } from "vue-router";
import { ElMenu, ElMenuItem, ElPopover } from "element-plus";
import { routes } from "./routes.js";
import "element-plus/es/components/menu/style/css";
import "element-plus/es/components/menu-item/style/css";
import "element-plus/es/components/popover/style/css";
const _hoisted_1 = { class: "gala-menu-header" };
const _hoisted_2 = { class: "flex gap-16px" };
const _hoisted_3 = {
  key: 0,
  class: "h-40px box-border px-12px flex items-center gap-8px text-14px font-bold border-solid border-0 border-b-1 border-[var(--color-grey-3)]"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "w-22px h-12px ml-4px"
};
const _hoisted_6 = {
  class: "gala-menu-header__item w-full mx-6px relative flex items-center relative gap-3px",
  text: "14px [--color-1]"
};
const _hoisted_7 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "w-full h-full"
};
const _hoisted_8 = {
  key: 1,
  src: _imports_1,
  alt: "",
  class: "w-full h-full"
};
const _hoisted_9 = {
  key: 0,
  class: "ds-icon-upward2 rotate-180 transition group-hover:rotate-0"
};
const _sfc_main = /* @__PURE__ */ defineComponent({
  __name: "Menu",
  props: {
    userInfo: {}
  },
  setup(__props) {
    const props = __props;
    const currRoute = useRoute();
    const router = useRouter();
    const dataRouter = computed(() => {
      const data = getDataRouter(routes);
      const res = data.map((item) => {
        const { children } = item;
        const obj = {
          ...item
        };
        if (children && (children == null ? void 0 : children.length)) {
          const noChildren = children.filter((child) => !child.children || !child.children.length);
          const hasChildren = children.filter((child) => child.children && child.children.length);
          if (noChildren.length) {
            const noChildrenData = {
              value: "",
              children: noChildren
            };
            obj.children = [noChildrenData, ...hasChildren];
          }
        }
        return obj;
      });
      return res;
    });
    function getDataRouter(data = [], res = []) {
      return data == null ? void 0 : data.reduce((acc, router2) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _i;
        const isMenu = (_a = router2 == null ? void 0 : router2.meta) == null ? void 0 : _a.isMenu;
        if (isMenu || ((_b = router2 == null ? void 0 : router2.meta) == null ? void 0 : _b.isMenuPopper)) {
          if ((_c = router2.meta) == null ? void 0 : _c.privilegeId) {
            if (!props.userInfo)
              return acc;
            const privilege = props.userInfo.privileges.find(({ moduleId }) => {
              var _a2;
              return moduleId === ((_a2 = router2.meta) == null ? void 0 : _a2.privilegeId);
            });
            if (!privilege)
              return acc;
          }
          const obj = {
            label: (_d = router2 == null ? void 0 : router2.meta) == null ? void 0 : _d.title,
            value: router2.path,
            icon: (_e = router2 == null ? void 0 : router2.meta) == null ? void 0 : _e.icon,
            isNewIcon: (_f = router2 == null ? void 0 : router2.meta) == null ? void 0 : _f.isNewIcon,
            isHotIcon: (_g = router2 == null ? void 0 : router2.meta) == null ? void 0 : _g.isHotIcon
          };
          if ((_h = router2 == null ? void 0 : router2.children) == null ? void 0 : _h.length)
            obj.children = getDataRouter(router2 == null ? void 0 : router2.children, []);
          acc.push(obj);
        } else {
          if ((_i = router2 == null ? void 0 : router2.children) == null ? void 0 : _i.length)
            getDataRouter(router2 == null ? void 0 : router2.children, res);
        }
        return acc;
      }, res);
    }
    const defaultPath = ref("");
    const defaultSecondPath = ref("");
    watch(() => currRoute.path, () => {
      var _a;
      const val = currRoute.path;
      defaultSecondPath.value = val;
      if (val.includes("analysis")) {
        if ((_a = currRoute.name) == null ? void 0 : _a.toString().includes("Ranking"))
          defaultPath.value = "/ranking";
        else
          defaultPath.value = val.split("/").slice(0, 2).join("/");
      } else {
        defaultPath.value = val.split("/").slice(0, 2).join("/");
      }
    }, {
      immediate: true
    });
    function goSecondPath(data) {
      router.push({ path: data.value });
    }
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("div", _hoisted_1, [
        createVNode(unref(ElMenu), {
          "default-active": defaultPath.value,
          class: "el-menu-demo",
          mode: "horizontal",
          "popper-class": "gala-menu-popper",
          router: "",
          style: { "--el-menu-hover-text-color": "var(--color-1)", "--el-menu-bg-color": "transparent", "--el-menu-active-color": "var(--color-1)" }
        }, {
          default: withCtx(() => [
            (openBlock(true), createElementBlock(Fragment, null, renderList(dataRouter.value, (routeItem) => {
              return openBlock(), createBlock(unref(ElMenuItem), {
                key: routeItem.value,
                index: routeItem.value,
                class: "group",
                style: { "--el-menu-hover-text-color": "var(--color-2)", "--el-menu-hover-bg-color": "var(--color-2-10)" }
              }, {
                default: withCtx(() => [
                  createVNode(unref(ElPopover), {
                    placement: "bottom",
                    trigger: "hover",
                    "popper-class": "gala-popper gala-menu-header-popper",
                    transition: "el-zoom-in-top",
                    "show-arrow": false,
                    disabled: !routeItem.children || !routeItem.children.length,
                    width: "auto",
                    "popper-options": {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, 5]
                          }
                        }
                      ]
                    }
                  }, {
                    reference: withCtx(() => [
                      createElementVNode("div", _hoisted_6, [
                        createTextVNode(toDisplayString(routeItem.label) + " ", 1),
                        createElementVNode("div", {
                          class: normalizeClass(["w-22px h-12px absolute right-8px top-8px flex gala-menu__item-icon", { "!right--8px": !routeItem.children }])
                        }, [
                          routeItem.isNewIcon ? (openBlock(), createElementBlock("img", _hoisted_7)) : createCommentVNode("", true),
                          routeItem.isHotIcon ? (openBlock(), createElementBlock("img", _hoisted_8)) : createCommentVNode("", true)
                        ], 2),
                        routeItem.children && routeItem.children.length ? (openBlock(), createElementBlock("i", _hoisted_9)) : createCommentVNode("", true)
                      ])
                    ]),
                    default: withCtx(() => [
                      createElementVNode("div", _hoisted_2, [
                        (openBlock(true), createElementBlock(Fragment, null, renderList(routeItem.children, (item, index) => {
                          return openBlock(), createElementBlock("div", {
                            key: index,
                            class: "flex flex-col min-w-120px"
                          }, [
                            item.value ? (openBlock(), createElementBlock("div", _hoisted_3, [
                              createElementVNode("i", {
                                class: normalizeClass(["gala-menu-header-popper__title-icon", item.icon])
                              }, null, 2),
                              createTextVNode(" " + toDisplayString(item.label), 1)
                            ])) : createCommentVNode("", true),
                            (openBlock(true), createElementBlock(Fragment, null, renderList(item == null ? void 0 : item.children, (itemInner, indexInner) => {
                              return openBlock(), createElementBlock("div", {
                                key: indexInner,
                                class: normalizeClass(["mt-8px cursor-pointer box-border px-12px flex items-center h-32px rounded-4px text-14px color-[var(--color-1)] hover:color-[var(--color-2)] hover:bg-[var(--color-2-10)]", { "gala-menu-header-popper__item-active": defaultSecondPath.value === itemInner.value }]),
                                onClick: ($event) => goSecondPath(itemInner)
                              }, [
                                createTextVNode(toDisplayString(itemInner.label) + " ", 1),
                                itemInner.isNewIcon ? (openBlock(), createElementBlock("img", _hoisted_5)) : createCommentVNode("", true)
                              ], 10, _hoisted_4);
                            }), 128))
                          ]);
                        }), 128))
                      ])
                    ]),
                    _: 2
                  }, 1032, ["disabled"])
                ]),
                _: 2
              }, 1032, ["index"]);
            }), 128))
          ]),
          _: 1
        }, 8, ["default-active"])
      ]);
    };
  }
});
export {
  _sfc_main as default
};
